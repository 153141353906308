/* eslint-disable max-len,react/no-unescaped-entities */
import React from 'react';
import { renderToString } from 'react-dom/server';

export function UseTermsHTML() {
  const html = (
    <section>
      <h1>Termo condições de uso</h1>
      <article>
        <h1>1. DO OBJETO E DA ACEITAÇÃO</h1>
        <p>
          1.1.	Estes Termos e Condições de Uso Unindo Sonhos ("Termos") regulam a utilização dos serviços de intermediação oferecidos pela empresa PC DE S LEITE LTDA. (“Unindo Sonhos”), inscrita no CNPJ sob o nº 16.742.811/0001-06, sediada na Av. Nossa Senhora da Consolata, 645 C, Centro, Boa Vista/Roraima, CEP: 69301 - 011, aos usuários da internet (“Usuário”), por meio do site Unindo Sonhos ("Plataforma”).
        </p>
        <p>1.3.	Ao acessar o site oficial da plataforma, o Usuário confirma que leu, compreendeu e aceita as disposições aqui estabelecidas, comprometendo-se a observar todas as regras detalhadas.</p>
        <p>
          1.3 O objeto do presente “Termos e Condições de Uso” é a intermediação realizada pela INTERMEDIADORA entre o USUÁRIO e CASAS LOTÉRICAS credenciadas pela CAIXA ECONÔMICA FEDERAL, para a realização, em nome do
          USUÁRIO, de apostas em jogos lotéricos oficiais, por meio de bolões, nos termos do art. 653 do Código Civil, servindo este instrumento como formalização da manifestação da vontade de contratar, realizada por meio da ciência e concordância de todos os termos constantes do Instagram @unindosonhoss e do respectivo pagamento realizado.
        </p>
        <p>1.4.	As seções abaixo complementam os presentes Termos e trazem informações adicionais importantes sobre a utilização e o funcionamento dos Serviços, além do que está previsto neste documento. Dessa forma, o Usuário assume o compromisso de conhecer integralmente o seu conteúdo.</p>
      </article>

      <article>
        <h1>2.	INDEPENDÊNCIA DA UNINDO SONHOS EM RELAÇÃO A ENTIDADES TERCEIRAS</h1>
        <p><b>2.1.	A UNINDO SONHOS declara expressamente que não mantém qualquer vínculo institucional, comercial ou operacional com a Caixa Econômica Federal ou com as casas lotéricas credenciadas. Estas entidades são consideradas terceiras, independentes e totalmente desvinculadas da relação contratual privada estabelecida exclusivamente entre a Unindo Sonhos e o Usuário. Tal independência reforça o caráter de intermediação dos serviços oferecidos pela plataforma, que opera de forma autônoma em conformidade com as normas legais vigentes.</b></p>
      </article>

      <article>
        <h1>3.	DOS SERVIÇOS DE INTERMEDIAÇÃO</h1>

        <p>3.1.	Os serviços oferecidos na Plataforma consistem na intermediação entre o Usuário e Casas Lotéricas credenciadas pela Caixa Econômica Federal, para a realização, em nome do Usuário, de bolões nas modalidades lotéricas oficiais. Essa intermediação é realizada nos termos do art. 653 e seguintes do Código Civil Brasileiro (Lei nº 10.406/2002), sendo o aceite dado pelo Usuário na Plataforma ou Aplicativo considerado como formalização de sua manifestação de vontade de contratar.</p>
        <p>3.2.	Em cumprimento da cláusula 3.1 acima, o Usuário concede, ao aceitar os presentes Termos, de forma irretratável, um mandato à Unindo Sonhos para que, em seu nome, realize os seguintes atos:
          a)	registrar os bolões nas casas lotéricas autorizadas;
          b)	conferir os resultados das apostas para verificar eventuais premiações;
          c)	resgatar os prêmios nas casas lotéricas ou junto à Caixa Econômica Federal; e
          d)	repassar ao Usuário os valores correspondentes às apostas premiadas.
        </p>
        <p>3.3.	Esta concessão de mandato é exclusivamente para os fins acima descritos, permitindo à Unindo Sonhos agir em nome do Usuário para facilitar a gestão e o processamento das apostas solicitadas na plataforma.</p>
        <p>3.4.	Eventuais dúvidas sobre os presentes termos deverão ser sanadas por meio dos canais de atendimento ao cliente previsto na clausula 19.1 abaixo.</p>
      </article>

      <article>
        <h1>4.  REMUNERAÇÃO DOS SERVIÇOS DE INTERMEDIAÇÃO</h1>
        <p>
          4.1. Pela prestação dos serviços oferecidos, a Unindo Sonhos, na qualidade de
          mandatária, receberá uma remuneração fixa correspondente à diferença entre
          o valor total pago pelo Usuário e o preço estipulado pela Caixa Econômica
          Federal para a realização da aposta, conforme previsto no art. 676 do
          Código Civil.
        </p>
        <p>
          4.2. A remuneração não inclui despesas adicionais, como transferências bancárias,
          impostos ou quaisquer outros custos relacionados à retirada ou repasse dos
          prêmios aos Usuários contemplados.
        </p>
        <p>
          4.3. Em caso de contemplação, nenhum valor adicional será devido à Unindo Sonhos
          como remuneração.
        </p>
        <p>
          4.4. A Unindo Sonhos não cobra taxas de acesso, permitindo que todo o conteúdo
          disponibilizado na plataforma (como dicas, informações e estatísticas) seja
          acessado gratuitamente pelos Usuários.
        </p>
      </article>

      <article>
        <h1>5. CONDIÇÕES NECESSÁRIAS PARA A UTILIZAÇÃO DA INTERMEDIAÇÃO PELO USUÁRIO</h1>
        <p>
          5.1. Ser, na data do cadastro, pessoa física, maior de idade (acima de 18 anos)
          e juridicamente capaz.
        </p>
        <p>
          5.2. Efetuar cadastro prévio na Plataforma ou no Aplicativo.
        </p>
        <p>
          5.3. O Usuário assegura e assume a responsabilidade pela veracidade, precisão,
          validade e autenticidade das informações fornecidas no cadastro que preencheu,
          comprometendo-se a mantê-las devidamente atualizadas.
        </p>
        <p>
          5.4. A Unindo Sonhos não tem a obrigação de monitorar ou verificar as informações
          fornecidas pelo Usuário, mas reserva-se o direito, a seu exclusivo critério, de
          remover qualquer informação que considere falsa ou ofensiva.
        </p>
        <p>
          5.5. A Unindo Sonhos realiza a verificação das informações fornecidas pelo Usuário
          no momento do cadastro, incluindo, mas não se limitando, à confirmação de identidade,
          idade e dados de contato, utilizando tecnologias e métodos adequados para garantir
          a veracidade dos dados.
        </p>
        <p>
          5.5.1. Caso sejam identificadas inconsistências, dados falsos ou incompletos, a Unindo
          Sonhos poderá, a seu exclusivo critério, solicitar documentos complementares ou
          suspender temporariamente a conta do Usuário até a devida regularização.
        </p>
        <p>
          5.5.2. A Unindo Sonhos reserva-se o direito de remover ou corrigir qualquer informação
          fornecida pelo Usuário que se mostre inexata, falsa ou ofensiva, sempre que tal medida
          seja necessária para assegurar a integridade da plataforma e o cumprimento dos Termos.
        </p>
      </article>


      <article>
        <h1>6. SENHAS DE ACESSO AO PORTAL E AO APLICATIVO</h1>
        <p>
          6.1. A senha de acesso à Plataforma e ao Aplicativo é pessoal e intransferível,
          sendo o Usuário integralmente responsável por sua utilização correta e segura.
        </p>
        <p>
          6.2. É expressamente proibido compartilhar a senha de acesso para terceiros.
        </p>
        <p>
          6.3. A senha de acesso é criptografada e deverá conter caracteres/números conforme
          solicitação no momento da realização do cadastro.
        </p>
        <p>
          6.4. A Sorte Unida não possui acesso à senha pessoal do Usuário.
        </p>
        <p>
          6.5. A senha poderá ser alterada pelo Usuário a qualquer momento, por meio da conta
          do Usuário na Plataforma ou no Aplicativo.
        </p>
        <p>
          6.6. Em caso de perda ou extravio da senha, o Usuário deve informar imediatamente a
          Unindo Sonhos para que sejam adotadas as providências necessárias. Qualquer prejuízo
          decorrente dessa situação será de total responsabilidade do Usuário.
        </p>
        <p>
          6.7. O Usuário se compromete a não permitir, e a tomar todas as medidas possíveis para
          evitar que seu cadastro seja acessado e/ou utilizado por terceiros, especialmente em
          relação ao uso via Aplicativo, sendo responsável por qualquer compartilhamento indevido
          de sua senha pessoal.
        </p>
      </article>


      <article>
        <h1>7. DAS MODALIDADES DE APOSTAS</h1>
        <p>
          7.1. A Unindo Sonhos oferece exclusivamente, por meio de sua Plataforma, a intermediação de serviços na modalidade de aposta conjunta (“Bolão”), que é disponibilizada em cotas estrategicamente elaboradas por sua equipe. Na criação dos bolões, são utilizados diversos recursos avançados, incluindo bases estatísticas extensas, análise combinatória, técnicas próprias de fechamento de combinações, filtros especializados e softwares de última geração.
        </p>
        <p>
          7.2. Os jogos previamente elaborados estão organizados em grupos, e o Usuário pode participar de duas formas:
        </p>
        <p>
          a) Conjunto com outros Usuários: Adquirindo uma ou mais cotas do grupo de jogos indicados.
        </p>
        <p>
          b) Individualmente: Adquirindo a totalidade das cotas do grupo de jogos indicados.
        </p>
        <p>
          7.3. O total de cotas de um grupo determina o número máximo de participantes para aquela possibilidade de aposta. Todos os apostadores do grupo concorrem com todas as apostas (cartões) incluídas no bolão, independentemente da quantidade de cotas adquiridas.
        </p>
        <p>
          7.4. No caso de premiações, os valores serão rateados proporcionalmente à quantidade de cotas adquiridas por cada apostador no grupo.
        </p>
        <p>
          7.5. As apostas poderão ser realizadas na Plataforma da Unindo Sonhos, enquanto houver cotas disponíveis, até às 19h59 (horário de Brasília) do dia do sorteio. Caso o pagamento seja efetuado após o prazo estipulado ou para um bolão já encerrado, o Usuário NÃO ESTARÁ APTO A PARTICIPAR DO SORTEIO CORRESPONDENTE. Nesses casos, o valor pago será reembolsado mediante solicitação, no prazo de até 3 (três) dias úteis após a confirmação do pagamento.
        </p>
        <p>
          7.6. Antes de concluir a solicitação da aposta, o Usuário deve verificar cuidadosamente todas as informações, como o bolão escolhido, a quantidade de cotas no carrinho e o concurso. Após a conclusão, o procedimento é irreversível, não sendo possível retificar dados ou solicitar reembolso de valores pagos.
        </p>
        <p>
          7.7. Em casos de inviabilidade no processamento das apostas para o concurso escolhido, por motivos de força maior ou caso fortuito, como problemas técnicos, condições climáticas adversas, falhas de terceiros ou outras situações imprevistas, a Unindo Sonhos estará isenta de responsabilidade. Nesses casos, as apostas serão automaticamente canceladas e o valor pago será integralmente reembolsado.
        </p>
        <p>
          7.7.1. Para fins de clareza da cláusula 7.7 acima, entende-se por força maior e caso fortuito, eventos fora do controle razoável das partes, que não podem ser previstos ou evitados, conforme disposto no artigo 393 do Código Civil Brasileiro. Exemplos incluem catástrofes naturais, conflitos armados, greves, epidemias, falhas de sistemas de comunicação ou energia, entre outros eventos imprevisíveis.
        </p>
        <p>
          7.7.2. Após a realização das apostas, a Unindo Sonhos se responsabilizará pela guarda e custódia dos bilhetes correspondentes pelo prazo de até 90 (noventa) dias após o sorteio.
        </p>
        <p>
          7.8. Caso a Caixa Econômica Federal descontinue a modalidade de loteria escolhida pelo Usuário, a Unindo Sonhos reembolsará o valor apostado, por meio de depósito ou crédito na conta indicada pelo Usuário, dentro do prazo estipulado na cláusula 7.5 acima.
        </p>
        <p>
          7.9. Em situações de atraso, adiamento ou cancelamento de concursos, a Unindo Sonhos notificará o Usuário assim que receber essas informações, com base nos dados fornecidos pelos canais oficiais do órgão regulamentador.
        </p>
        <p>
          7.10. A Unindo Sonhos reserva-se o direito de adicionar, modificar ou remover serviços e/ou produtos disponíveis na Plataforma Digital, a qualquer momento, sem a necessidade de aviso prévio. Nessas situações, não caberá ao Usuário qualquer direito a indenização ou reembolso em razão das alterações realizadas.
        </p>
      </article>


      <article>
        <h1>8. DO PAGAMENTO PARA A INTERMEDIAÇÃO DAS APOSTAS</h1>
        <p>
          8.1. Os pagamentos deverão ser realizados por meio de PIX ou depósito bancário
          (exclusivo para compra de crédito). O Usuário é responsável por guardar o comprovante
          da transação, que poderá ser solicitado para conferência ou como condição para o
          exercício de eventuais benefícios e direitos.
        </p>
        <p>
          8.2. Todas as transações realizadas são submetidas a avaliação de risco por fornecedores
          de serviço especializados, sem qualquer interferência ou participação da Unindo Sonhos.
        </p>
        <p>
          8.3. Não é admitido o parcelamento das compras na Plataforma, somente pagamentos à vista.
        </p>
        <p>
          8.4. O Usuário também pode acompanhar o status da efetivação de seu bolão acessando a
          opção "Minha Conta" &gt; "Minhas Apostas" para verificar se o bolão selecionado está
          marcado como "Concluído".
        </p>
        <p>
          8.5. Caso necessário, a Unindo Sonhos poderá solicitar documentos comprobatórios para
          atestar a legitimidade de transações relacionadas a compra de créditos ou bolões para
          a conta do Usuário.
        </p>
      </article>


      <article>
        <h1>9. DO PROCESSAMENTO DOS RESULTADOS E REPASSE DOS PRÊMIOS</h1>
        <p>
          9.1. A Unindo Sonhos realizará a apuração dos sorteios e o rateio dos prêmios entre os Usuários.
          Em caso de premiação, o Usuário contemplado será notificado por e-mail no endereço cadastrado em sua conta.
          Alternativamente, o Usuário poderá verificar seus prêmios diretamente na opção “Minhas Apostas”, que será
          atualizada em até 3 (três) dias úteis após o sorteio.
        </p>
        <p>
          9.2. Em caso de premiação, a Unindo Sonhos resgatará, em nome do Usuário, eventuais valores oriundos de prêmios
          contemplados junto à Caixa Econômica Federal, conforme disposto no art. 653 e seguintes do Código Civil.
        </p>
        <p>
          9.3. O repasse dos valores premiados será realizado conforme o prazo necessário para a liberação dos recursos
          pela Caixa Econômica Federal, podendo haver variações.
        </p>
        <p>
          9.4. O valor correspondente ao rateio do prêmio será creditado na aba "Minha Carteira" da conta do Usuário na
          Plataforma, em até 3 (três) dias úteis após o sorteio, salvo nos casos em que o prazo de liberação dos valores
          pela Caixa Econômica Federal, conforme disposto na cláusula 9.3 acima, exija tempo adicional. O Usuário poderá optar por:
        </p>
        <p>
          a) manter o saldo na "Carteira Digital" para uso em futuras apostas; ou
        </p>
        <p>
          b) solicitar a transferência do valor para sua conta bancária, acessando a mesma aba "Minha Carteira" no menu.
          Para transferências, o valor mínimo disponível na "Carteira Digital" deve ser de R$ 20,00 (vinte reais).
        </p>
        <p>
          9.5. Para cobrir os custos operacionais de transferências bancárias, será deduzida uma taxa fixa de R$ 5,00 (cinco reais) por transação.
        </p>
        <p>
          9.6. Após a solicitação de resgate pelo Usuário, os prêmios serão transferidos para a conta bancária indicada, em até
          5 (cinco) dias úteis, nos termos das cláusulas 9.3 e 9.4 acima. A transferência será realizada em parcela única e o
          comprovante será considerado como prova definitiva de pagamento, não cabendo ao Usuário qualquer reclamação após a
          efetivação da transferência.
        </p>
      </article>

      <article>
        <h1>10. DO ACESSO À INTERMEDIAÇÃO PELO PORTAL / APLICATIVO</h1>
        <p>
          10.1. A Unindo Sonhos concede ao Usuário acesso ao Portal e ao Aplicativo mediante uma licença revogável,
          onerosa e intransferível de uso.
        </p>
        <p>
          10.2. A Unindo Sonhos se reserva o direito, a qualquer momento e sem necessidade de aviso prévio, de:
        </p>
        <p>
          a) Suspender ou excluir o acesso do Usuário ao Portal e/ou Aplicativo caso sejam constatadas condutas ilícitas ou
          que violem a moral, a ética e os bons costumes, como ofensas a outros usuários ou operadores, solicitação de cancelamento
          de apostas com justificativas falsas ou fraudulentas, entre outras práticas inadequadas.
        </p>
        <p>
          b) Remover informações do cadastro do Usuário que sejam incompatíveis com os Termos, ou que contenham conteúdo ofensivo,
          discriminatório, homofóbico, racial, xenofóbico ou qualquer outra forma de discriminação.
        </p>
        <p>
          c) Suspender, encerrar ou excluir o cadastro do Usuário caso existam suspeitas fundamentadas de que as apostas estejam
          sendo utilizadas para fins ilícitos, incluindo a violação de leis relacionadas a crimes contra o sistema financeiro
          nacional (Lei nº 7.492/1986) ou lavagem de dinheiro (Lei nº 9.613/1998). Nesses casos, a Unindo Sonhos poderá notificar
          as autoridades competentes.
        </p>
        <p>
          10.3. O Usuário não poderá:
        </p>
        <p>
          a) Utilizar a Plataforma ou Aplicativo para divulgar informações ou realizar manifestações contrárias à legislação vigente.
        </p>
        <p>
          b) Copiar, vender, ceder, locar, reproduzir, doar, transferir ou alienar, parcial ou totalmente, o Aplicativo ou informações
          relacionadas ao seu código-fonte.
        </p>
        <p>
          c) Utilizar softwares, malwares ou quaisquer artifícios para burlar ou causar prejuízo à Unindo Sonhos, sob pena de
          responsabilização civil e criminal.
        </p>
        <p>
          d) Reproduzir, adaptar, modificar ou utilizar, no todo ou em parte, o Portal ou Aplicativo sem a autorização expressa
          da Unindo Sonhos.
        </p>
        <p>
          e) Publicar conteúdo ou realizar ações que prejudiquem a imagem da Unindo Sonhos ou de outros Usuários.
        </p>
        <p>
          f) Simular a aquisição de créditos por transações financeiras, como PIX ou boleto bancário, para requerer resgate imediato
          do montante correspondente. Tal prática será considerada violação dos Termos e poderá resultar no bloqueio do acesso à
          conta, cancelamento das transações e responsabilização civil e criminal, conforme aplicável.
        </p>
        <p>
          g) Realizar engenharia reversa.
        </p>
      </article>


      <article>
        <h1>11. LIMITAÇÃO DE RESPONSABILIDADE</h1>
        <p>
          11.1. A Unindo Sonhos não se responsabilizará, em nenhuma hipótese:
        </p>
        <p>
          a) Por eventuais prejuízos sofridos pelo Usuário em razão de falhas no sistema de informática, de telefonia ou em servidores
          que o impeçam de usufruir, de qualquer forma, da intermediação ofertada pela Unindo Sonhos;
        </p>
        <p>
          b) Por eventuais prejuízos oriundos de fraudes praticadas por terceiros, devendo o Usuário manter, às suas expensas e sob sua
          responsabilidade, softwares e medidas de proteção contra vírus, malwares, worms etc;
        </p>
        <p>
          c) Por situações decorrentes de caso fortuito, força maior ou culpa exclusiva de terceiro.
        </p>
        <p>
          11.2. A Unindo Sonhos NÃO garante que as funções contidas na Plataforma atendam a todas as necessidades dos Usuários, que a
          operação da Plataforma será ininterrupta ou livre de erros, que qualquer funcionalidade continuará disponível, que os defeitos
          na Plataforma serão corrigidos ou que a Plataforma será compatível ou funcione com qualquer Aplicativo, aplicações ou serviços
          de terceiros.
        </p>
        <p>
          11.3. O Usuário concorda em defender, indenizar e manter indene a Unindo Sonhos e suas afiliadas, diretores, empregados e
          agentes, de e contra quaisquer encargos, ações ou demandas, incluindo, mas não limitado a honorários advocatícios razoáveis,
          resultantes de:
        </p>
        <p>
          (i) sua eventual utilização indevida da Plataforma e do Aplicativo; ou
        </p>
        <p>
          (ii) sua violação das condições pactuadas nestes Termos.
        </p>
        <p>
          11.4. Em nenhum caso a Unindo Sonhos será responsável por danos pessoais ou qualquer prejuízo incidental, especial, indireto
          ou consequente, incluindo lucros cessantes, tais como prejuízos por perda de lucro, corrupção ou perda de dados, falha de
          transmissão ou recepção de dados, perda de chance, interrupção de negócios ou qualquer outro prejuízo ou perda comercial
          decorrentes ou relacionados ao uso ou à impossibilidade de uso do Aplicativo por qualquer motivo.
        </p>
        <p>
          11.5. Caso a Unindo Sonhos seja compelida, por decisão judicial transitada em julgado, a indenizar ou ressarcir o Usuário por
          danos sofridos, o valor devido estará limitado a 20% (vinte por cento) da totalidade das quantias efetivamente pagas pelo
          Usuário à Unindo Sonhos.
        </p>
      </article>


      <article>
        <h1>12. DAS CONTAS SEM MOVIMENTAÇÃO E COBRANÇA DE TARIFAS</h1>
        <p>
          12.1. Caso a conta do Usuário permaneça sem qualquer movimentação por um período superior a 180 (cento e oitenta) dias, a
          Unindo Sonhos poderá cobrar uma tarifa mensal de R$ 10,00 (dez reais) para a manutenção da conta.
        </p>
        <p>
          12.1.1. Para efeitos da cláusula 12.1 acima, será considerada inativa a conta que não registrar movimentações por mais de 180
          (cento e oitenta) dias consecutivos.
        </p>
        <p>
          12.2. A tarifa mencionada será descontada automaticamente do saldo disponível na conta do Usuário, independentemente de sua
          origem, seja ela de prêmios recebidos ou créditos adquiridos para uso na plataforma.
        </p>
        <p>
          12.2.1. O débito será realizado mensalmente, limitado ao saldo existente na conta do Usuário.
        </p>
        <p>
          12.2.2. Caso a conta não possua saldo suficiente para cobrir a tarifa, a Unindo Sonhos poderá desativar temporariamente a conta
          do Usuário até que novos créditos sejam adquiridos ou aportados na plataforma.
        </p>
      </article>


      <article>
        <h1>13. TERMOS DE USO DE PARCEIROS E TERCEIROS</h1>
        <p>
          13.1. A Plataforma ou o Aplicativo da Unindo Sonhos pode conter links para sites de empresas parceiras, que possuem seus próprios termos e condições de uso.
        </p>
        <p>
          13.2. A Unindo Sonhos não se responsabiliza pelos termos e condições de uso de sites externos relacionados à plataforma, seja por meio de links, divulgação ou outras formas de acesso.
        </p>
      </article>

      <article>
        <h1>14. INFORMAÇÕES COLETADAS E UTILIZAÇÃO DE COOKIES</h1>
        <p>
          14.1. A Unindo Sonhos utiliza cookies, pequenos arquivos que permitem analisar o comportamento dos usuários dentro do site e outros serviços, com o objetivo de aprimorar a experiência de navegação. Esses arquivos não armazenam informações pessoais sem o consentimento do Usuário e não coletam dados registrados no computador ou celular do Usuário.
        </p>
        <p>
          14.2. O Usuário pode, a qualquer momento, configurar seu navegador para bloquear a instalação de cookies ou ser informado quando eles estiverem em uso. No entanto, essa escolha pode limitar ou prejudicar o acesso a algumas funcionalidades da plataforma.
        </p>
      </article>

      <article>
        <h1>15. DA PRIVACIDADE</h1>
        <p>
          15.1. A Unindo Sonhos e seus colaboradores comprometem-se a agir em conformidade com a legislação vigente de proteção de dados pessoais, em especial a Lei nº 13.709/2018 (“Lei Geral de Proteção de Dados”), bem como outras normas aplicáveis e diretrizes dos órgãos reguladores e fiscalizadores.
        </p>
        <p>
          15.2. A Unindo Sonhos possui um documento denominado Política de Privacidade, que detalha de forma clara como os dados dos Usuários serão coletados, utilizados, compartilhados e armazenados. A Política de Privacidade pode ser consultada a qualquer momento na Plataforma ou no Aplicativo.
        </p>
      </article>


      <article>
        <h1>16. DA VIGÊNCIA</h1>
        <p>
          16.1. Estes Termos e Condições de Uso terão vigência por prazo indeterminado, entrando em vigor a partir do aceite do Usuário, momento em que passam a ter força de contrato entre as partes.
        </p>
        <p>
          16.2. Os presentes Termos podem ser alterados ou rescindidos unilateralmente pela Unindo Sonhos, a qualquer momento, sem qualquer ônus, mediante comunicação ao Usuário por meio do Aplicativo, Plataforma, e-mail ou outro meio idôneo.
        </p>
      </article>


      <article>
        <h1>17. DAS REGRAS GERAIS</h1>
        <p>
          17.1. Na utilização da Plataforma ou Aplicativo, o Usuário compromete-se a agir com responsabilidade, integridade e respeito à legislação vigente, à moral e aos bons costumes.
        </p>
        <p>
          17.2. A incapacidade ou a impossibilidade de a Unindo Sonhos exercer ou fazer cumprir qualquer direito ou disposição ora prevista não representa renúncia desse direito ou disposição.
        </p>
        <p>
          17.3. A declaração de nulidade ou inaplicabilidade de que qualquer item, termo ou disposição ora previstos, não implicará a nulidade ou inaplicabilidade de quaisquer outros itens, termos ou disposições aqui contidos, os quais permanecerão em pleno vigor e cujos efeitos não serão atingidos.
        </p>
        <p>
          17.4. As disposições destes Termos, assim como quaisquer informações ou orientações divulgadas por meio da Plataforma ou do Aplicativo, constituem as normas que regem a relação entre o Usuário e a Unindo Sonhos.
        </p>
        <p>
          17.5. Em nenhuma hipótese o Usuário terá acesso ao código-fonte do Portal Digital ou Aplicativo, por se tratar de propriedade intelectual exclusiva da Unindo Sonhos.
        </p>
        <p>
          17.6. Todo o conteúdo da Plataforma e do Aplicativo, incluindo textos, gráficos, imagens, logos, ícones, fotografias, materiais editoriais e notificações, é propriedade exclusiva da Unindo Sonhos e protegido pela Lei de Propriedade Intelectual (Lei nº 9.609/98).
        </p>
        <p>
          17.7. O Usuário é responsável pelas informações e dados bancários fornecidos à Unindo Sonhos. Esta não poderá ser responsabilizada por prejuízos decorrentes de incorreções nesses dados que impeçam o recebimento de prêmios.
        </p>
        <p>
          17.8. O valor dos prêmios pode variar conforme as regras do concurso estipuladas pela Caixa Econômica Federal. A Unindo Sonhos não se responsabiliza por eventuais alterações ou ajustes realizados pela Caixa.
        </p>
        <p>
          17.9. A Unindo Sonhos não é uma instituição financeira e, portanto, não serão pagos acréscimos ou rendimentos sobre valores mantidos na Carteira Digital do Usuário.
        </p>
        <p>
          17.10. Os prêmios em loterias resgatados são tributados diretamente na fonte. O montante recebido pelo Usuário já é líquido e corresponde à quantia final após a devida tributação. O Usuário é exclusivamente responsável por declarar tais valores em sua Declaração de Imposto de Renda, conforme exigido pela legislação fiscal vigente.
        </p>
      </article>


      <article>
        <h1>18. DA LEGISLAÇÃO APLICÁVEL E DO FORO</h1>
        <p>
          18.1. A relação contratual entre o Usuário e a Unindo Sonhos é regida pelas disposições do Código Civil Brasileiro relativas ao Contrato de Mandato, artigos 653 e seguintes da Lei nº 10.406/2002.
        </p>
        <p>
          18.2. Em cumprimento às regras nacionais e internacionais de prevenção à corrupção, lavagem de dinheiro e financiamento de atividades criminosas, a Unindo Sonhos poderá compartilhar informações contratuais com órgãos governamentais, conforme permitido pela legislação vigente.
        </p>
        <p>
          18.3. As partes elegem o Foro da Comarca de São Paulo/SP como o competente para dirimir judicialmente quaisquer controvérsias relacionadas ao uso da Plataforma ou Aplicativo da Unindo Sonhos.
        </p>
        <p>
          18.4. O Usuário declara ter ciência dos direitos e obrigações decorrentes dos presentes Termos, tendo lido, compreendido e aceito todos os termos e condições.
        </p>

      </article>
      <article>

        <h1>19. DO ATENDIMENTO AO USUÁRIO</h1>
        <p>
          19.1. Quaisquer dúvidas sobre os serviços podem ser esclarecidas diretamente na Plataforma Digital, nas seções 'Quem Somos', 'FAQ' ou por meio da aba 'Central de Atendimento ao Cliente', disponível via WhatsApp, Telegram ou e-mail. O atendimento ocorre de segunda a sexta-feira, das 8h às 18h, e aos sábados, das 8h às 12h (horário de Roraima).
        </p>
      </article>



      <p>São Paulo, 12 de dezembro de 2024.</p>
    </section>
  );

  return renderToString(html);
}
