const data = {
    data: `<h1>Termo de Consentimento para Tratamento de Dados</h1>
            <article>
                <p>
                    Este documento formaliza a manifestação livre, informada e inequívoca pela qual o Titular concorda com o tratamento de seus dados pessoais para finalidades específicas, conforme disposto na Lei nº 13.709/18 – Lei Geral de Proteção de Dados Pessoais (LGPD).
Ao aceitar este termo, o Titular autoriza a <b>Unindo Sonhos</b> (<a href="https://www.instagram.com/unindosonhoss/" target="_blank">@Unindosonhoss</a>), registrada sob o CNPJ nº 16.742.811/0001-06, com sede na Av. Nossa Senhora da Consolata, 645 c – Centro, Boa Vista/RR, CEP: 69301-011, doravante denominada <b>“CONTROLADORA”</b>, a realizar o tratamento de seus dados pessoais, bem como a tomar decisões sobre tal tratamento. O tratamento abrange operações como coleta, produção, classificação, uso, acesso, reprodução, transmissão, armazenamento, eliminação, análise, compartilhamento, transferência e outras previstas em lei.
                </p>
            </article>

            <article>
                <h1>1.	DADOS PESSOAIS TRATADOS</h1>
                <p> A CONTROLADORA está autorizada a tratar os seguintes dados pessoais fornecidos pelo Titular: </p>
                <ul>
                    <li>a)	Informações pessoais: nome completo, data de nascimento, RG, CPF, CNH, fotografia 3x4, estado civil, filiação, nível de instrução e endereço completo.</li>
                    <li>b)	Contato: telefones, WhatsApp, e-mails e identificações em redes sociais.</li>
                    <li>c)	Dados financeiros: banco, agência, contas bancárias, bandeira, número, validade e código de cartões de crédito.</li>
                    <li>d)	Credenciais: nome de usuário e senha para uso dos serviços da Unindo Sonhos.</li>
                    <li>e)	Movimentações financeiras na Plataforma Eletrônica ou Aplicativo.</li>
                    <li>f)	Dados inseridos pelo Titular para fins de divulgação ou publicidade.</li>
                </ul>
            </article>

            <article>
                <h1>2.	FINALIDADES DO TRATAMENTO</h1>
                <p>
                    O tratamento dos dados terá as seguintes finalidades:
                </p>
                <ul>
                    <li> a)	Identificar e contatar o Titular para relacionamento comercial.</li>
                    <li> b)	Elaborar contratos e emitir cobranças.</li>
                    <li> c)	Fornecer produtos e serviços, remunerados ou gratuitos.</li>
                    <li> d)	Estruturar, testar, promover e divulgar produtos e serviços.</li>
                    <li> e)	Realizar pesquisas de mercado e promover eventos, prêmios ou concursos.</li>
                    <li> f)	Emitir relatórios, notas fiscais e documentos financeiros correlatos.</li>
                    <li> g)	Facilitar a prestação de serviços adicionais contratados pelo Titular.</li>
                    <li> h)	Mitigar fraudes eletrônicas e realizar análises de risco com base no histórico de transações.</li>
                    <li> i)	Gerar estatísticas e análises, sem individualização dos dados.</li>
                </ul>
            </article>

            <article>
                <h1>3.	COMPARTILHAMENTO DE DADOS</h1>
                <p>A Unindo Sonhos poderá compartilhar os dados pessoais do Titular com terceiros, caso seja necessário para cumprir as finalidades descritas neste termo, sempre observando os princípios da LGPD.</p>
            </article>

            <article>
                <h1>4.	SEGURANÇA DOS DADOS</h1>
                <p>A CONTROLADORA se compromete a adotar medidas de segurança técnicas e administrativas para proteger os dados pessoais contra acessos não autorizados, incidentes e tratamentos inadequados ou ilícitos.</p>
                <p>Em caso de incidentes de segurança que possam gerar riscos ao Titular, a CONTROLADORA notificará a Autoridade Nacional de Proteção de Dados (ANPD) e o Titular, conforme previsto no art. 48 da LGPD.</p>
            </article>

            <article>
                <h1>5.	TÉRMINO DO TRATAMENTO</h1>
                <p>
                    Os dados pessoais serão tratados enquanto forem necessários para atingir as finalidades descritas. Dados anonimizados, sem possibilidade de identificação, poderão ser mantidos por prazo indeterminado. O Titular poderá solicitar a exclusão de seus dados pessoais, ciente de que essa solicitação pode inviabilizar a continuidade dos serviços.
                </p>
                <p>O Titular autoriza a Unindo Sonhos, nos termos do art. 7º, VI da LGPD, a conservar os dados pelo prazo de 10 (dez) anos para eventual exercício de direitos fundamentais em processos judiciais ou administrativos.</p>
            </article>

            <article>
                <h1>6.	DIREITOS DO TITULAR</h1>
                <p>
                    O Titular pode, a qualquer momento, exercer os seguintes direitos:
                </p>
                <ul>
                    <li> a)	Confirmar a existência de tratamento de dados.</li>
                    <li> b)	Acessar os dados pessoais tratados.</li>
                    <li> c)	Corrigir dados incompletos, inexatos ou desatualizados.</li>
                    <li> d)	Solicitar a anonimização, bloqueio ou eliminação de dados desnecessários ou tratados em desconformidade com a LGPD.</li>
                    <li> e)	Requisitar a portabilidade dos dados para outro fornecedor de serviços, conforme regulamentação.</li>
                    <li> f)	Solicitar informações sobre o compartilhamento de dados.</li>
                    <li> g)	Ser informado sobre as consequências de não fornecer consentimento.</li>

                </ul>
            </article>

            <article>
                <h1>7.	DIREITO DE REVOGAÇÃO DO CONSENTIMENTO</h1>
                <p>O consentimento pode ser revogado a qualquer momento, mediante solicitação via e-mail ou correspondência à Unindo Sonhos.</p>
            </article>`
}
export default data;
